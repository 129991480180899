import qs from "query-string";
import { createUserManager } from "redux-oidc";

import environment from "../envConfig";
import { DRAW_PARAM, PARENT_PARAM } from "./constants";

const { ssoClientId, ssoOpenIdUrl } = environment.app;

const getUrl = (path) => {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }${path}`;
};

const userManagerConfig = {
  authority: ssoOpenIdUrl,
  automaticSilentRenew: true,
  client_id: ssoClientId,
  filterProtocolClaims: true,
  loadUserInfo: true,
  post_logout_redirect_uri: getUrl("/signout"),
  redirect_uri: getUrl("/signin"),
  response_type: "id_token token", // COULD BE response_type "code" for code mode no imply
  scope: "openid profile email roles tags",
  silent_redirect_uri: getUrl("/renew"),
};

const userManager = createUserManager(userManagerConfig);

/**
 * For this operation we could use jwt.decode npm module but it needs node functionnality polyfill to work with webpack5.
 * Anyway it doens't make sense to use a non browser functionnality.
 * So here we use our own function to decode a jsonwebtoken
 */
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return {};
  }
};

let renewTimeout;
const getData = (logOut) => {
  // Takes boolean parameter to verify if logging out to remove draw.id
  let urlParams = window.location.search;
  if (logOut) {
    const params = qs.parse(urlParams);
    if (!params[PARENT_PARAM]) {
      delete params[DRAW_PARAM];
    }
    urlParams = `?${qs.stringify(params)}`;
  }
  return {
    data: {
      urlParams,
      urlPathname: window.location.pathname,
    },
  };
};

// Test if the current jwt token is going to expire in the next 20 seconds.
export const isTokenExpiring = (user) => {
  if (!user) {
    return true;
  }
  const expireDateInScds = parseJwt(user.id_token).exp || 0;
  const expireDate = new Date(expireDateInScds * 1000);
  return expireDate - Date.now() - 20000 <= 0;
};

export const stopSigninSilent = () => {
  clearTimeout(renewTimeout);
};

export const startSigninSilent = () => {
  stopSigninSilent(renewTimeout);
  renewTimeout = setTimeout(() => {
    userManager.signinSilent();
  }, environment.app.ssoRenewInterval);
};

export const signin = (data) => {
  return userManager.signinRedirect(data || getData());
};

export const signout = () => {
  return userManager.signoutRedirect(getData(true)).then(() => {
    sessionStorage.removeItem(`oidc.user:${ssoOpenIdUrl}:${ssoClientId}`);
    userManager.removeUser(); // In newer Chrome versions parallel tabs do not register the logout, so we double-force it
  });
};

export default userManager;
