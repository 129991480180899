import { ThemeProvider } from "@material-ui/core/styles";
import "ol/ol.css";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { OidcProvider } from "redux-oidc";

import "./App.scss";
import "./Globals.scss";
import store from "./model/store";
import defaultTheme from "./themes";
import userManager from "./utils/userManager";

const renderChildren = (children, childrenProps) => {
  return React.Children.map(children, (child) => {
    return React.cloneElement(child, childrenProps);
  });
};

function App({ children }) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const history = useMemo(() => {
    return {
      push: (path) => {
        navigate(path);
      },
      replace: (path) => {
        navigate(path);
      },
    };
  }, [navigate]);

  const childrenProps = useMemo(() => {
    return { history, location, params };
  }, [params, history, location]);

  return (
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <ThemeProvider theme={defaultTheme}>
          {renderChildren(children, childrenProps)}
        </ThemeProvider>
      </OidcProvider>
    </Provider>
  );
}

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(App);
