import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createDebounce from "redux-debounced";
import createOidcMiddleware, { loadingUser, reducer as oidc } from "redux-oidc";
import { thunk } from "redux-thunk";

import environment from "../envConfig";
import { WARN_DIALOG_NAME } from "../utils/constants";
import userManager from "../utils/userManager";
import app from "./app/reducers";
import map from "./map/reducers";

// Allow to use Redux dev tools in FF and Chrome
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const { previewPathname, ssoClientId, ssoOpenIdUrl } = environment.app;

const debounceMiddleware = createDebounce();
const oidcMiddleware = createOidcMiddleware(userManager);

const store = createStore(
  combineReducers({
    app,
    map,
    oidc,
  }),
  composeEnhancers(applyMiddleware(oidcMiddleware, debounceMiddleware, thunk)),
);

// We want to load the user only on the main route /, not on sso callback routes /signin, /signout and /renew
if (!/\/(signin|signout|renew)/.test(window.location)) {
  store.dispatch(loadingUser());
  userManager.signinSilent().catch((error) => {
    if (
      /draw.id=[A-Za-z0-9]+/.test(window.location.search) &&
      window.location.pathname !== previewPathname &&
      !store.getState().app.parentOptions // The user can save anonymously when mapset is opened by trafimage.
    ) {
      // eslint-disable-next-line no-console
      console.error(new Error("Silent signin failed: "), error);
      sessionStorage.removeItem(`oidc.user:${ssoOpenIdUrl}:${ssoClientId}`);
      userManager.removeUser(); // In newer Chrome versions parallel tabs do not register the logout, so we double-force it
      store.dispatch({
        data: {
          dialogVisible: WARN_DIALOG_NAME,
        },
        type: "SET_DIALOG_VISIBLE",
      });
    }
  });
}

export default store;
